<template>
  <div>
    <v-row class="fill-height body mt-2">
      <v-col cols="12" md="6" v-for="mail in mails" v-bind:key="mail.id" :id="mail.id">
        <mail-card v-bind:mail="mail" v-on:updateMails="getMails()"></mail-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MailCard from "@/components/unassignedMails/MailCard";
export default {
  name: "MailLayout",
  components: {MailCard},
  props: {
    mails: {
      required: true
    },
  },
  data: () => ({}),
  methods: {
    getMails(){
      this.$emit("updateMails")
    }
  },
}
</script>

<style scoped>

</style>
